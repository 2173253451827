import { Icon } from '@fluentui/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IOneNavigationPage, IOneNavigationPageMenuAction } from "@one/core";

export interface IOneNavigationRow {
	page: IOneNavigationPage;
	level: number;
	selectedItem: IOneNavigationPage | null;
	mobileMenu?: boolean;
	children?: React.ReactNode;
	actionMenuVisible: boolean;
	isEditMode: boolean;
	setActiveActionMenu: Dispatch<SetStateAction<IOneNavigationPage | undefined>>;
	onClick: () => void;
	onMenuClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onDragStart: (event: any) => void;
	onDragEnd: (event: any) => void;
	onDragOver: (event: any) => void;
	onDragEnter: (event: any) => void;
	onDragLeave: (event: any) => void;
	onDrop: (event: any) => void;
}
export const OneNavigationRow = (props: IOneNavigationRow) => {
	const [isOpen, setIsOpen] = useState(false);

	let hasChildren: boolean = props.children !== undefined && props.children !== false;
	let isActive: boolean = props.selectedItem && props.selectedItem.id === props.page.id ? true : false;

	useEffect(() => {
		if (props.selectedItem && props.page && props.selectedItem.path.includes(props.page.path)) {
			setIsOpen(true);
		}
	}, [props.selectedItem])

	const onClick = () => {
		if (!isOpen) {
			setIsOpen(!isOpen);
		}
		props.onClick();
	};

	const onMenuActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: IOneNavigationPageMenuAction, p: IOneNavigationPage) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		action.action(p);
		props.setActiveActionMenu(undefined);
	}

	const onCollapseClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		setIsOpen(!isOpen)
	};

	useEffect(() => {
		let item = document.querySelector(`.one-navigation-row[data-page-id="${props.page.id}"]`);
		if (item) {
			if (props.isEditMode) {
				item.addEventListener('dragstart', props.onDragStart);
				item.addEventListener('dragover', props.onDragOver);
				item.addEventListener('dragend', props.onDragEnd);
				item.addEventListener('dragenter', props.onDragEnter);
				item.addEventListener('dragleave', props.onDragLeave);
				item.addEventListener('drop', props.onDrop);
			} else {
				item.removeEventListener('dragstart', props.onDragStart);
				item.removeEventListener('dragover', props.onDragOver);
				item.removeEventListener('dragend', props.onDragEnd);
				item.removeEventListener('dragenter', props.onDragEnter);
				item.removeEventListener('dragleave', props.onDragLeave);
				item.removeEventListener('drop', props.onDrop);
			}
		}
	}, [props.isEditMode])

	return (
		<>
			<div draggable={props.isEditMode} className={`one-navigation-row ${isActive ? 'active' : ''} ${hasChildren ? 'hasChildren' : ''}`} data-page-id={props.page.id} key={props.page.id} data-level={props.level}>

				<div className="item" onClick={() => onClick()}>

					<div className="collapseIcon" onClick={(e) => onCollapseClick(e)}>
						{hasChildren
							? <Icon iconName={isOpen ? "ChevronDown" : "ChevronRight"}></Icon>
							: undefined}
					</div>
					<div className="icon">
						{props.page.icon ? <Icon iconName={props.page.icon}></Icon> : undefined}
					</div>
					<div className="name">
						<div className="dnd-above-below above">
							<div></div>
						</div>
						{props.page.name}
						<div className="dnd-above-below below">
							<div></div>
						</div>
					</div>


					{props.isEditMode && props.page.actions && props.page.actions.length > 0 && (
						<>
							<div className="menuIcon" onClick={(e) => props.onMenuClick(e)}>
								<Icon iconName="MoreVertical"></Icon>
							</div>

							<div className={`actions ${props.actionMenuVisible ? 'active' : ''}`}>
								<div className="overlay"></div>
								<div className="menu">

									{props.page.actions && props.page.actions.map((action, index) => {
										return (
											<div className="action" key={index} onClick={(e) => onMenuActionClick(e, action, props.page)} >
												<div className="icon">
													<Icon iconName={action.icon}></Icon>
												</div>
												<div className="name">{action.name}</div>
											</div>
										);
									})}
								</div>
							</div>
						</>
					)}

				</div>

				{hasChildren ? <div className={`children ${isOpen ? 'open' : 'closed'}`}>{props.children}</div> : undefined}
			</div>
		</>
	)
}
