export default {
	// --== General ==--
	"general.yes": "Ja",
	"general.no": "Nee",
	"general.save": "Opslaan",
	"general.cancel": "Annuleren",

	"inputs.iconpicker.panel.header": "Icoon selecteren",
	"inputs.iconpicker.placeholder": "Selecteer een icoon",
	"inputs.iconpicker.searchbar.placeholder": "Zoek naar iconen...",
	"inputs.iconpicker.search.no.results": "Geen iconen gevonden met deze zoekterm.",
	"inputs.iconpicker.selected": "Geselecteerd",
	"inputs.iconpicker.selected.clear": "Wis geselecteerde",
	"inputs.iconpicker.none.selected": "Geen",

	"navigation.actions.edit.start": "Bewerken",
	"navigation.actions.edit.stop": "Bewerken stoppen",
};
