export default {
	// --== General ==--
	"general.yes": "Yes",
	"general.no": "No",
	"general.save": "Save",
	"general.cancel": "Cancel",

	"inputs.iconpicker.panel.header": "Icon picker",
	"inputs.iconpicker.placeholder": "Select an icon",
	"inputs.iconpicker.searchbar.placeholder": "Search for icons...",
	"inputs.iconpicker.search.no.results": "Could not find any icons with this search term.",
	"inputs.iconpicker.selected": "Selected",
	"inputs.iconpicker.selected.clear": "Clear selected",
	"inputs.iconpicker.none.selected": "None",

	"navigation.actions.edit.start": "Edit",
	"navigation.actions.edit.stop": "Stop editing",
};
