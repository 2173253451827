import React from "react";
import "./ApplicationHeaderTitle.scss";

export interface IApplicationHeaderTitle {
	title: string;
}

export const ApplicationHeaderTitle = (props: IApplicationHeaderTitle) => {
	return <div className="one-application-header-title">{props.title}</div>;
};
