import * as React from "react";
import "./ApplicationHeaderSection.scss";

export interface IApplicationHeaderSectionProps {
	children?: React.ReactNode;
	hidden?: boolean;
}

export const ApplicationHeaderSection = (props: IApplicationHeaderSectionProps) => {
	if (props.hidden === true) {
		return null;
	}
	return <div className="one-application-header-section">{props.children}</div>;
};
