import * as React from "react";
import { Icon } from "@fluentui/react";

export interface IApplicationHeaderButtonProps {
	img?: string;
	imgAlt?: string;
	iconName?: string;
	href?: string;
	target?: string;
	onClick?: () => void;
}

export const ApplicationHeaderButton = (props: IApplicationHeaderButtonProps) => {
	const content = (
		<>
			{props.img !== undefined && <img src={props.img} alt={props.imgAlt} />}
			{props.iconName !== undefined && <Icon iconName={props.iconName} />}
		</>
	);

	if (props.href !== undefined) {
		return (
			<a
				className="one-application-header-button"
				onClick={props.onClick}
				href={props.href}
				target={props.target}
			>
				{content}
			</a>
		);
	}

	return (
		<div className="one-application-header-button" onClick={props.onClick}>
			{content}
		</div>
	);
};
