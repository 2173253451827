import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import * as React from "react";
import "./ApplicationActionBar.scss";

export interface IApplicationActionBarProps {
	items?: ICommandBarItemProps[];
	farItems?: ICommandBarItemProps[];
	includeNavBarPadding?: boolean;
}

export const ApplicationActionBar = (props: IApplicationActionBarProps) => {
	const navbarPaddingPx = 250;

	let itemsWithClass: ICommandBarItemProps[] = [];
	if (props.items) {
		itemsWithClass = props.items.map((item) => {
			return {
				...item,
				className: "one-application-action-bar-button",
			};
		});
	}

	let farItemsWithClass: ICommandBarItemProps[] = [];
	if (props.farItems) {
		farItemsWithClass = props.farItems.map((item) => {
			return {
				...item,
				className: "one-application-action-bar-button",
			};
		});
	}

	return (
		<section
			className="one-application-action-bar-wrapper"
			style={props.includeNavBarPadding === true ? { paddingLeft: navbarPaddingPx } : undefined}
		>
			<CommandBar className="one-application-action-bar" items={itemsWithClass} farItems={farItemsWithClass} />
		</section>
	);
};
