import * as React from "react";
import "./PageContainer.scss";

export interface IPageContainer {
	children?: React.ReactNode;
}

export const PageContainer = (props: IPageContainer) => {
	return <div className="one-page-container">{props.children}</div>;
};
