import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { delay, hasValue } from "@one/core";
import { ActionButton, DefaultButton, FocusZone, Icon, PanelType, PrimaryButton, SearchBox, TextField } from "@fluentui/react";

import allIconNames from "@fluentui/font-icons-mdl2/lib/data/AllIconNames.json";

import { OnePanel, OnePanelContent } from "../../surfaces";

export interface IIconPickerProps {
    selectedIcon: string | null;
    onSave: (iconName: string | null) => void;
    label?: string | undefined;
    errorMessage?: string | undefined;
}
export const IconPicker = (props: IIconPickerProps) => {
    const intl = useIntl();
    const [panelIsOpen, setPanelIsOpen] = React.useState(false);
    const [allIconsUi, setAllIconsUi] = useState<JSX.Element | JSX.Element[]>(<React.Fragment />);
    const [filteredIcons, setFilteredIcons] = useState<any[] | null>(null);

    const [internalSelectedIcon, setInternalSelectedIcon] = useState<string | null>(props.selectedIcon || null);

    React.useEffect(() => {
        if (!panelIsOpen) {
            setInternalSelectedIcon(null);
        } else {
            setInternalSelectedIcon(props.selectedIcon);
        }
    }, [panelIsOpen]);

    const _closePanel = () => {
        setPanelIsOpen(false);
        setInternalSelectedIcon(props.selectedIcon || null);
        setFilteredIcons(null);
    };

    const onRenderCell = React.useCallback((item: any) => {
        return (
            <div
                key={item.name}
                className={"one-icon-grid-tile"}
                title={item.name}
                onClick={() => {
                    setInternalSelectedIcon(item.name);
                }}
            >
                <div className="one-icon-grid-tile-content">
                    <div className="one-icon-grid-tile-content-icon">
                        <Icon iconName={item.name} />
                    </div>
                    <div className="one-icon-grid-tile-content-name">
                        <span>{item.name}</span>
                    </div>
                </div>
            </div>
        );
    }, []);

    const onRenderFooterContent = () => {
        return (
            <div className="one-icon-picker-footer">
                <DefaultButton text={intl.formatMessage({ id: "general.cancel" })} onClick={() => _closePanel()} />
                <div className="one-icon-picker-footer-selected">
                    <span>
                        <FormattedMessage id="inputs.iconpicker.selected" />:
                    </span>
                    {internalSelectedIcon !== null ? (
                        <Icon iconName={internalSelectedIcon} />
                    ) : (
                        <FormattedMessage id="inputs.iconpicker.none.selected" />
                    )}
                </div>
                <PrimaryButton
                    text={intl.formatMessage({ id: "general.save" })}
                    onClick={() => {
                        props.onSave(internalSelectedIcon);
                        _closePanel();
                    }}
                />
            </div>
        );
    };

    const goFilter = (input: string | undefined) => {
        if (hasValue(input) && input !== undefined && input.trim() !== "") {
            const filtered = allIconNames.filter(
                (icon) => icon.name !== undefined && icon.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
            );

            setFilteredIcons(filtered);
        } else {
            setFilteredIcons(null);
        }
    };

    React.useEffect(() => {
        setAllIconsUi(
            allIconNames.map((icon, k) => {
                if (icon && icon.name !== undefined) {
                    return onRenderCell(icon);
                }
                return <React.Fragment key={k}></React.Fragment>;
            })
        );
    }, []);

    const renderContent = () => {
        if (filteredIcons !== null) {
            if (filteredIcons.length > 0) {
                return <>{filteredIcons.map((icon) => onRenderCell(icon))}</>;
            } else {
                return (
                    <div className="one-icon-grid-no-results">
                        <Icon iconName="Page" />
                        <span>
                            <FormattedMessage id="inputs.iconpicker.search.no.results" />
                        </span>
                    </div>
                );
            }
        }

        return allIconsUi;
    };

    return (
        <React.Fragment>
            <TextField
                readOnly
                label={props.label}
                errorMessage={props.errorMessage}
                styles={{
                    field: {
                        cursor: "pointer",
                    },
                    icon: {
                        fontSize: "12px",
                    },
                }}
                onClick={() => setPanelIsOpen(true)}
                value={props.selectedIcon || ""}
                placeholder={intl.formatMessage({ id: "inputs.iconpicker.placeholder" })}
                iconProps={{ iconName: props.selectedIcon || "ChevronDown" }}
            />
            <OnePanel
                headerText={intl.formatMessage({ id: "inputs.iconpicker.panel.header" })}
                isOpen={panelIsOpen}
                onDismiss={() => _closePanel()}
                type={PanelType.largeFixed}
                onRenderFooterContent={onRenderFooterContent}
                styles={{
                    content: {
                        overflow: "hidden",
                    },
                }}
            >
                <OnePanelContent style={{ height: "calc(100% - 33px)" }}>
                    <div className="icon-picker-header">
                        <SearchBox
                            placeholder={intl.formatMessage({ id: "inputs.iconpicker.searchbar.placeholder" })}
                            onSearch={(value) => goFilter(value)}
                            onChange={(_e, value) =>
                                delay(() => {
                                    goFilter(value);
                                }, 500)
                            }
                            onClear={() => goFilter(undefined)}
                        />
                        <ActionButton
                            iconProps={{ iconName: "Trash" }}
                            text={intl.formatMessage({ id: "inputs.iconpicker.selected.clear" })}
                            onClick={() => setInternalSelectedIcon(null)}
                            disabled={internalSelectedIcon === null}
                        />
                    </div>
                    <FocusZone className="one-icon-grid" data-is-scrollable="true">
                        {renderContent()}
                    </FocusZone>
                </OnePanelContent>
            </OnePanel>
        </React.Fragment>
    );
};
