import React from "react";
import {
    Panel,
    PanelType,
    Icon,
    IRenderFunction,
    IPanelProps,
    IStyleFunctionOrObject,
    IPanelStyleProps,
    IPanelStyles,
} from "@fluentui/react";

export interface IOnePanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    children?: React.ReactNode;
    headerText?: string;
    onRenderFooter?: IRenderFunction<IPanelProps> | undefined;
    onRenderFooterContent?: IRenderFunction<IPanelProps> | undefined;
    /**
     * @defaultvalue PanelType.smallFixedFar
     */
    type?: PanelType;

    className?: string;
    styles?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> | undefined;
}

export const OnePanel = (props: IOnePanelProps) => {
    const panelType = props.type === undefined ? PanelType.smallFixedFar : props.type;
    const renderPanelHeader = () => {
        return (
            <section className="one-panel-header">
                <h1>{props.headerText}</h1>

                <div className="one-panel-close" onClick={props.onDismiss}>
                    <Icon iconName="ChromeClose" />
                </div>
            </section>
        );
    };

    return (
        <Panel
            className={`one-panel ${props.className || ""}`}
            styles={props.styles}
            isOpen={props.isOpen}
            type={panelType}
            onDismiss={props.onDismiss}
            isLightDismiss
            onLightDismissClick={props.onDismiss}
            closeButtonAriaLabel="Close"
            onRenderNavigation={renderPanelHeader}
            onRenderFooter={props.onRenderFooter}
            onRenderFooterContent={props.onRenderFooterContent}
        >
            {props.children}
        </Panel>
    );
};
