import { ITag } from "@fluentui/react";
import { ITerm } from "../interfaces";

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
	if (!tagList || !tagList.length || tagList.length === 0) {
		return false;
	}
	return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const filterSuggestedTags = (input: ITag[], filterText: string, tagList: ITag[]): ITag[] => {
	return filterText
		? input.filter(
				(tag) =>
					tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList)
		  )
		: [];
};

// Term specific

/**
 * Loops through the terms recursively and determines which terms should be selectable as tags..
 */
export const determineSelectableTags_terms = (terms: ITerm[], result: ITag[] = []) => {
	for (let i = 0; i < terms.length; i++) {
		const term = terms[i];
		// If the term is active
		if (term.isActive) {
			if (term.childTerms && term.childTerms.length > 0) {
				result = determineSelectableTags_terms(term.childTerms, result);
			}

			// Add the current term to the result if it is selectable
			if (term.isSelectable) {
				result.push({ key: term.id, name: term.name } as ITag);
			}
		}
	}

	return result;
};

export const listContainsTagList_terms = (tag: ITag, tagList?: ITag[]) => {
	if (!tagList || !tagList.length || tagList.length === 0) {
		return false;
	}
	return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const filterSuggestedTags_terms = (terms: ITerm[], filterText: string, tagList: ITag[]): ITag[] => {
	return filterText
		? determineSelectableTags_terms(terms).filter(
				(tag) =>
					tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList)
		  )
		: [];
};
