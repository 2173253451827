import * as React from "react";

export interface IPageContentProps {
    /**
     * @defaultvalue true
     */
    enableOverflowScroll?: boolean;
    children?: React.ReactNode;
}

export const PageContent = (props: IPageContentProps) => {
    const enableOverflowScroll = props.enableOverflowScroll === undefined ? true : props.enableOverflowScroll;
    const className = enableOverflowScroll ? "one-page-content-scroll" : "one-page-content";
    return <div className={className}>{props.children}</div>;
};
