import * as React from "react";

export interface IOnePanelContentProps {
    children?: React.ReactNode;
    style?: React.CSSProperties | undefined;
}

export const OnePanelContent = (props: IOnePanelContentProps) => {
    return (
        <section className="one-panel-content" style={props.style}>
            {props.children}
        </section>
    );
};
