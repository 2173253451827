import * as React from "react";
import { useEffect, useState } from "react";
import { OneNavigationRow } from "./oneNavigationRow/OneNavigationRow";
import { ActionButton, Panel, PanelType } from "@fluentui/react";
import { IOneNavigationPage, LoadingOverlay } from "@one/core";
import { useIntl } from "react-intl";

export interface IOneNavigationProps {
	pages: IOneNavigationPage[];
	hasEditPermissions: boolean;
	isLoading: boolean;
	selectedItem: IOneNavigationPage | null;
	setSelectedItem: (newValue: IOneNavigationPage | null) => void;
	mobileView: boolean;
	mobilePanelIsOpen: boolean;
	setMobilePanelIsOpen: (newValue: boolean) => void;
	footer?: JSX.Element;
	updatePageOrder: (draggedElementId: number, newParentId: number | null, order: number) => void;
}

export const OneNavigation = (_props: IOneNavigationProps) => {
	const intl = useIntl();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [activeActionMenu, setActiveActionMenu] = useState<IOneNavigationPage>();
	const [flatPageList, setFlatPageList] = useState<IOneNavigationPage[]>([]);

	const onItemClick = (p: IOneNavigationPage) => {
		setActiveActionMenu(undefined);
		_props.setSelectedItem(p);

		if (p.onClick) {
			p.onClick(p);
		}
	};

	const onMenuClick = (e: any, p: IOneNavigationPage) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		setActiveActionMenu(p);
		_props.setSelectedItem(p);
	};

	/**
	 * Creates a flat list of re protal pages
	 * @param input
	 * @param result
	 * @returns
	 */
	const flattenPages = (input: IOneNavigationPage[], result: IOneNavigationPage[] = []) => {
		for (let i = 0; i < input.length; i++) {
			let page = input[i];
			result.push(page);
			if (page.children && page.children.length > 0) {
				result = flattenPages(page.children, result);
			}
		}

		return result;
	};

	useEffect(() => {
		if (_props.pages.length > 0) {
			setFlatPageList(flattenPages(_props.pages));
		}
	}, [_props.pages]);

	useEffect(() => {
		let page = flatPageList.filter((x) => x.path === window.location.pathname);
		if (page.length > 0) {
			_props.setSelectedItem(page[0]);
		}
	}, [flatPageList]);

	useEffect(() => {
		if (_props.selectedItem) {
		}
	}, [_props.selectedItem]);

	/**
	 * Contains the Id of the page element.
	 * This is required because some drag function can't get
	 * the data trough dataTransfer.getData()-function.
	 */
	let draggedElementIdForDrag;

	/**
	 * Returns wether an pageId is a direct- or nested child
	 * of the target
	 * @param draggedItemId
	 * @param toItemId
	 * @returns
	 */
	const isNestedChildOf = (draggedItemId: number, toItemId: number | null) => {
		let toItem = flatPageList.filter((f) => f.id === toItemId)[0];

		if (toItem && toItem.parentPageId === draggedItemId) {
			return true;
		}

		return toItem && toItem.parentPageId !== null && isNestedChildOf(draggedItemId, toItem.parentPageId ? toItem.parentPageId : null);
	};

	/**
	 * Removes all the hover classes to reset the style.
	 * Removes it from the rows and from the D&D-elements.
	 */
	const removeAllHoverClasses = () => {
		let items = document.querySelectorAll(".one-navigation-row, .dnd-above-below");
		items.forEach(function (item) {
			item.classList.remove("hover");
		});
	};

	/**
	 * Triggerd when a drag operation is started. Set a opacity to the dragged
	 * element and change the cursor.
	 * Note: This function gets executed a lot, so don't put heavy/recursive
	 * functions in here.
	 * @param e
	 */
	const onDragStart = (e) => {
		e.target.style.opacity = "0.4";
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("Text", e.target.getAttribute("data-page-id"));
		draggedElementIdForDrag = parseInt(e.target.getAttribute("data-page-id"));
	};

	/**
	 * Triggerd when a drag operation has ended. Set's the opacity back to one
	 * and removes all the hover classes.
	 * @param e
	 */
	const onDragEnd = (e) => {
		e.target.style.opacity = "1";
		removeAllHoverClasses();
	};

	/**
	 * Triggered when dragging over another element.
	 * Note: This function gets executed a lot, so don't put heavy/recursive
	 * functions in here.
	 * @param e
	 * @returns
	 */
	const onDragOver = (e) => {
		if (e.preventDefault) {
			e.preventDefault();
		}

		//if the target is a direct- or nested child of the dragged element
		//prevent it from dropping.
		let hoveredRowId = parseInt(e.target.closest(".one-navigation-row").getAttribute("data-page-id"));
		if (isNestedChildOf(draggedElementIdForDrag, hoveredRowId) || draggedElementIdForDrag === hoveredRowId) {
			e.dataTransfer.dropEffect = "none";
			e.dataTransfer.effectAllowed = "none";
		}

		return false;
	};

	/**
	 * Triggered when first entering a new target element.
	 * @param e
	 */
	const onDragEnter = (e) => {
		let hoveredRowId = parseInt(e.target.closest(".one-navigation-row").getAttribute("data-page-id"));

		//add a hover effect on the right element, but only if it isn't a nested child or itself.
		if (!isNestedChildOf(draggedElementIdForDrag, hoveredRowId) && hoveredRowId !== draggedElementIdForDrag) {
			if (e.target.classList.contains("dnd-above-below")) {
				e.target.classList.add("hover");
			} else {
				e.target.closest(".one-navigation-row").classList.add("hover");
			}
		}
	};

	/**
	 *
	 * @param e
	 */
	const onDragLeave = (e) => {
		if (e.target.classList.contains("dnd-above-below")) {
			e.target.classList.remove("hover");
		} else {
			e.target.closest(".one-navigation-row").classList.remove("hover");
		}
	};

	const onDrop = (eventTo) => {
		eventTo.stopPropagation();
		eventTo.preventDefault();

		const draggedElementId = parseInt(eventTo.dataTransfer.getData("Text"));
		let newParentId: number | null = parseInt(eventTo.target.closest(".one-navigation-row").getAttribute("data-page-id"));

		removeAllHoverClasses();

		//only trigger the functions if its not dragged on itself.
		if (draggedElementId !== newParentId) {
			//if the item where it's dragged to isn't a nested child of itself
			if (!isNestedChildOf(draggedElementId, newParentId)) {
				//if the classlist contains this class it means it should be placed
				//above or below an item.
				if (eventTo.target.classList.contains("dnd-above-below")) {
					//find the current index/order of the items it was dragged above/below
					let item = document.querySelector(`.one-navigation-row[data-page-id="${newParentId}"]`);

					if (item) {
						let onePageNav = document.querySelector(`.one-page-navigation`);
						let childrenDiv = item.closest(".children");
						let childNodes;
						let order = 1;

						if (childrenDiv) {
							childNodes = Array.prototype.slice.call(childrenDiv.children);
						}
						//it was in the root, so use NULL as parentId
						else if (onePageNav) {
							newParentId = null;
							childNodes = Array.prototype.slice.call(onePageNav.children);
						}

						if (childNodes) {
							let index = childNodes.indexOf(item) + 1;
							order = eventTo.target.classList.contains("below") ? index + 1 : index;

							let isDraggedToRoot =
								eventTo.target.closest(".one-navigation-row").closest(".children") === null ? true : false;

							//because the in-between elements are in within a .one-navigation-row
							//we need the parent of that row
							if (isDraggedToRoot) {
								newParentId = null;
							} else {
								//because the in-between elements are in within a .one-navigation-row
								//we need the parent of that row (so the parent of the parent)
								newParentId = parseInt(
									eventTo.target
										.closest(".one-navigation-row")
										.closest(".children")
										.closest(".one-navigation-row")
										.getAttribute("data-page-id")
								);
							}

							_props.updatePageOrder(draggedElementId, newParentId, order);
						}
					}
				}
				//if the classlist didn't contains that class it means it was
				//dragged directly on top of an other page. Meaning the
				//dragged item should be a child of this page.
				//Always drop it at the end of the childs
				else {
					let item = document.querySelector(`.one-navigation-row[data-page-id="${newParentId}"]`);

					if (item) {
						let childrenDiv = item.closest(".children");
						let sequence = 1;

						if (childrenDiv) {
							sequence = childrenDiv.children.length + 1;
						}

						_props.updatePageOrder(draggedElementId, newParentId, sequence);
					} else {
						console.log("ERROR: one-navigation-row element was not found.");
					}
				}
			} else {
				console.log("ERROR: You can't  drag  a parent into it's children.");
			}
		}
		return false;
	};

	const renderRow = (page: IOneNavigationPage, level: number = 0) => {
		return (
			<OneNavigationRow
				page={page}
				level={level}
				key={page.id}
				selectedItem={_props.selectedItem}
				onClick={() => onItemClick(page)}
				onMenuClick={(e) => onMenuClick(e, page)}
				actionMenuVisible={activeActionMenu && activeActionMenu.id === page.id ? true : false}
				setActiveActionMenu={setActiveActionMenu}
				isEditMode={isEditMode}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				{page.children !== undefined &&
					page.children.length > 0 &&
					page.children.map((childPage) => {
						return renderRow(childPage, level + 1);
					})}
			</OneNavigationRow>
		);
	};

	const renderRootActionBar = () => {
		return (
			<>
				<div className="spacer"></div>
				<div className="one-page-navigation-footer">
					{isEditMode && _props.footer}

					{_props.hasEditPermissions && (
						<div className="action">
							<ActionButton iconProps={{ iconName: "Edit" }} onClick={() => setIsEditMode(!isEditMode)}>
								{isEditMode
									? intl.formatMessage({ id: "navigation.actions.edit.stop" })
									: intl.formatMessage({ id: "navigation.actions.edit.start" })}
							</ActionButton>
						</div>
					)}
				</div>
			</>
		);
	};

	const renderView = () => {
		if (_props.mobileView) {
			return (
				<Panel
					isOpen={_props.mobilePanelIsOpen}
					isLightDismiss={true}
					type={PanelType.smallFixedNear}
					styles={{
						content: {
							padding: 0,
							height: "100%",
						},
						scrollableContent: {
							height: "100%",
						},
					}}
					onDismiss={() => _props.setMobilePanelIsOpen(false)}
				>
					<div className="one-page-navigation">
						{renderRows()}
						{renderRootActionBar()}
					</div>
				</Panel>
			);
		}

		return (
			<div className="one-page-navigation">
				{_props.isLoading && <LoadingOverlay />}

				{renderRows()}
				{renderRootActionBar()}
			</div>
		);
	};

	const renderRows = () => {
		return _props.pages.map((page) => {
			return renderRow(page);
		});
	};

	return renderView();
};
