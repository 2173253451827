import * as React from "react";
import "./NavBar.scss";

export interface INavBarProps {
	children?: React.ReactNode;
}

export const NavBar = (props: INavBarProps) => {
	return <div className="one-nav-bar">{props.children}</div>;
};
