import { PermissionCodes } from "../enums";
import { IOneUser } from "../interfaces";
import { hasValue } from "./GeneralFunctions";

export const hasAccess = (oneUser: IOneUser, requiredPermissions: PermissionCodes[]) => {
	if (hasValue(oneUser) && hasValue(oneUser.permissions)) {
		for (let i = 0; i < oneUser.permissions.length; i++) {
			const userPermission = oneUser.permissions[i];

			for (let n = 0; n < requiredPermissions.length; n++) {
				const requiredPermission = requiredPermissions[n];
				if (userPermission.code === requiredPermission) {
					return true;
				}
			}
		}
	}

	return false;
};
