import * as React from "react";
import "./ApplicationHeaderLogo.scss";

export interface IApplicationHeaderLogoProps {
	img: string;
	imgAlt?: string;
}

export const ApplicationHeaderLogo = (props: IApplicationHeaderLogoProps) => {
	return (
		<div className="one-application-header-logo">
			<img src={props.img} alt={props.imgAlt} />
		</div>
	);
};
