export enum PermissionCodes {
	// One for You
	ManageAddresses = "Manage_Addresses",
	ManageAnnouncements = "Manage_Announcements",
	ManageShortcuts = "Manage_Shortcuts",
	ManageVacancies = "Manage_Vacancies",
	ManageNews = "Manage_News",
	ManageSocial = "Manage_Social",
	ManageStartupMessages = "Manage_StartupMessages",
	ViewUsageReporting = "ViewUsageReporting",

	//One for Events
	ManageEvents = "Manage_Events",
	ManageMailTemplates = "Manage_MailTemplates",
	PublishEvents = "Publish_Events",
	SetEventResources = "Set_Resources",

	//One for Teams
	ManageTeams = "Manage_Teams",

	//One Admin Portal
	ViewTenants = "View_Tenants", //Special permission!
	ViewApplicationReleases = "View_ApplicationReleases",
	ManageApplicationReleases = "Manage_ApplicationReleases", //Special permission!
	ManageJobs = "Manage_Jobs", //Special permission!
	ManageLookupList = "Manage_LookupLists",
	ManageUsers = "Manage_Users",
	ManageTaxonomy = "Manage_Taxonomy",
	ManageFeedback = "Manage_Feedback",
	ManageTeamTypes = "Manage_TeamTypes",
	ManageAudiences = "Manage_Audiences",
	ManageApplicationSettings = "Manage_Application_Settings",

	//One for Reporting
	ManageReportingPortalPages = "Manage_ReportingPortal_Pages",
	ManageReportingPortalSettings = "Manage_ReportingPortal_Settings",
}
