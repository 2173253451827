import * as React from "react";
import "./Skeleton.scss";

export interface ISkeletonProps {
	children?: React.ReactNode;
}

export const Skeleton = (props: ISkeletonProps) => {
	return <div className="one-skeleton">{props.children}</div>;
};
